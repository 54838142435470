import centerimage from './centerImage.jpeg'
import './App.css'
import LogoTop from './logo.PNG'
import AppImage from './appDownImage.webp'
import PhoneImage from './phoneCallImage.webp'
import { isIOS } from 'react-device-detect';

function App() {
    const phoneNumber = '3000-3000';
    const handleClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

  return (
    <div className="App">
      <img className={'logoTop'} src={LogoTop} alt={'logoTop'}/>

      <img className={'centerImage'} src={centerimage} alt={'centerImage'} onClick={handleClick}/>

      {/*  <a className={'appContainer'} href={isIOS ? 'https://apps.apple.com/kr/app/3000-3000-%EB%8C%80%EB%A6%AC%EC%9A%B4%EC%A0%84/id1662041820' : 'https://play.google.com/store/apps/details?id=com.logisoft.logiapp.smartcall_38047&pli=1'}>*/}
      {/*      <img className={'appDownImage'} src={AppImage} alt={'appDownImage'}/>*/}
      {/*  </a>*/}

      {/*<a className={'phoneCall'} href={'tel:3000-3000'}>*/}
      {/*  <img className={'appDownImage'} src={PhoneImage} alt={'appDownImage'}/>*/}
      {/*</a>*/}
    </div>
  );
}

export default App;
